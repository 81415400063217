<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="CONTACT" bg="/images/sub/sv-contact.jpg">
                <template #title>CONTACT</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <template #tit>CONTACT</template>
            <iframe data-aos="fade-up" class="rounded-lg d-block mb-40px mb-md-80px" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3017.746441588164!2d-73.96724612355571!3d40.855490428818264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f6c15de48fd5%3A0x3a5dc00555acc457!2s1%20Brg%20Plz%20N%20%23665%2C%20Fort%20Lee%2C%20NJ%2007024%2C%20USA!5e0!3m2!1sen!2skr!4v1705553998045!5m2!1sen!2skr" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <v-row justify="center" no-gutters>
                <v-col v-for="(item, index) in contact" :key="index" cols="12" md="" class="border-light" data-aos="fade-up" :data-aos-delay="$vuetify.breakpoint.mdAndUp ? index*100 : 0 "
                :class="{ 'border-l': index !== 0 && $vuetify.breakpoint.mdAndUp, 'border-t': index !== 0 && !$vuetify.breakpoint.mdAndUp }">
                    <card-secondary innerClass="pa-16px px-md-24px py-md-0 text-center">
                        <v-img :src="item.icon" max-width="48" :aspect-ratio="1 / 1" class="mx-auto mb-12px mb-md-24px" />
                        <u-tit-default class="tit--xs line-height-1">{{item.title}}</u-tit-default>
                        <u-txt-default>{{item.txt}}</u-txt-default>
                    </card-secondary>
                </v-col>
            </v-row>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardSecondary,
    },
    data() {
        return {
            contact: [
                {
                    title: "Company",
                    txt: "CS Renewables, Inc.",
                    icon: "/images/sub/contact/contact-icon.svg",
                },
                {
                    title: "Address",
                    txt: "1 Bridge Plaza, Suite 665, Fort Lee, NJ 07024",
                    icon: "/images/sub/contact/contact-icon2.svg",
                },
                {
                    title: "Phone",
                    txt: "(201) 429-2027",
                    icon: "/images/sub/contact/contact-icon3.svg",
                },
                {
                    title: "FAX",
                    txt: "(201) 429-2207",
                    icon: "/images/sub/contact/contact-icon4.svg",
                },
                {
                    title: "Email",
                    txt: "info@csrenewables.us",
                    icon: "/images/sub/contact/contact-icon5.svg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
