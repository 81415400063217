<template>
    <v-row class="row--xxl flex-md-row-reverse">
        <v-col cols="12" md="6">
            <v-img data-aos="zoom-out" data-aos-delay="200" :src="image" :aspect-ratio="588 / 500" max-width="588" class="rounded-lg" />
        </v-col>
        <v-col cols="12" md="6" class="mt-20px mt-md-0">
            <div class="pr-md-56px pt-md-48px">
                <tit-wrap-primary class="tit-wrap--sm"><slot name="title"/></tit-wrap-primary>
                <u-txt-default data-aos="fade-up" class="txt--lg line-height-15">
                    <slot />
                </u-txt-default>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        image: { type: String, default: "" },
    },
    components: {
        TitWrapPrimary,
        UTxtDefault,
    }
}
</script>

<style>

</style>