var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "WHO WE ARE",
            "tabActive": "OUR VALUES",
            "bg": "/images/sub/sv-intro.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("WHO WE ARE")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    scopedSlots: _vm._u([{
      key: "pageSectionHead",
      fn: function () {
        return [_c('v-img', {
          staticClass: "mx-auto mb-40px mb-md-80px",
          attrs: {
            "data-aos": "fade-up",
            "src": "/images/sub/intro/values/values-img.jpg",
            "max-width": "1920",
            "aspect-ratio": 1920 / 400
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "mx-md-n40px",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "px-md-40px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('tit-wrap-primary', {
    staticClass: "tit-wrap--sm"
  }, [_vm._v(" OUR VALUES ")])], 1), _c('v-col', {
    staticClass: "px-md-40px",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-txt-default', {
    staticClass: "txt--lg line-height-15"
  }, [_c('p', {
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" CS Renewables(“CSR”) is a utility-scale renewable energy and storage developer focused on accelerating clean energy future in the US. ")]), _c('p', {
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" Due to the ongoing climate change, natural disasters happen more and more often and affect communities economically and socially. In addition to this issue and the increasing demand in electricity, clean renewable energy gains prominence. ")]), _c('p', {
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" This is why we are dedicated to the renewable energy industry. We are leading the way to influence people, businesses and institutions throughout the US, with the commitment to work for the future, for the people, for the world. ")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }