<template>
    <v-row align="center" class="row--xxl" :class="{ 'flex-md-row-reverse': reverse }">
        <v-col cols="12" md="6">
            <v-img data-aos="zoom-out" data-aos-delay="200" :src="image" :aspect-ratio="560 / 400" max-width="560" class="rounded-lg" />
        </v-col>
        <v-col data-aos="fade-up" cols="12" md="6" class="mt-4px mt-md-0">
            <u-txt-default class="txt--lg line-height-15">
                <slot />
            </u-txt-default>
        </v-col>
    </v-row>
</template>

<script>
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
export default {
    props: {
        image: { type: String, default: "" },
        reverse: { type: Boolean, default: false },
    },
    components: {
        UTxtDefault,
    }
}
</script>

<style>

</style>