<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="WHO WE ARE" tabActive="OUR TEAM" bg="/images/sub/sv-intro.jpg">
                <template #title>WHO WE ARE</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first">
            <template #tit>OUR TEAM</template>
            <image-row image="/images/sub/intro/team/team-img.jpg">
                <p>We are proud to introduce the best team with 70+ years of combined experience. The CSR team expertly manages even the most complicated projects from concept to completion, and helps utilities, municipalities, cooperatives, and corporate customers deliver dependable, customized energy solutions.</p>
            </image-row>
        </page-section-primary>

        <page-section-primary class="page-section--last pt-0">
            <v-row>
                <v-col v-for="(item, index) in team" :key="index" data-aos="fade-up" :data-aos-delay="100*(index+1)" cols="6" sm="4">
                    <dialog-primary>
                        <template #activator="{ attrs, on }">
                            <v-card v-bind="attrs" v-on="on" flat rounded="lg" class="team-item">
                                <v-responsive :aspect-ratio="384 / 500" max-width="384">
                                    <v-img :src="item.image" :aspect-ratio="384 / 500" class="team-item__image" />
                                    <div class="team-item__inner pa-16px pa-md-24px">
                                        <u-txt-default class="white--text mb-4px mb-md-8px">{{item.txt}}</u-txt-default>
                                        <u-tit-default class="white--text tit--sm">{{item.title}}</u-tit-default>
                                    </div>
                                    <div class="team-item__active pa-16px">
                                        <u-txt-default class="white--text line-height-1 mb-4px mb-md-8px">{{item.txt}}</u-txt-default>
                                        <u-tit-default class="white--text">{{item.title}}</u-tit-default>
                                        <div class="mt-8px mt-md-20px"><btn-text-primary2 size="small">Learn More</btn-text-primary2></div>
                                    </div>
                                </v-responsive>
                            </v-card>
                        </template>
                        <v-row align-xl="center" class="row--xl">
                            <v-col cols="12" md="4" lg="auto">
                                <v-img :src="item.image" :aspect-ratio="384 / 500" max-width="384" :width="$vuetify.breakpoint.lgAndUp ? 384 : ''" />
                            </v-col>
                            <v-col cols="12" md="8" lg="" class="mt-10px mt-md-0">
                                <div class="pb-12px pb-md-24px">
                                    <u-txt-default class="primary--text line-height-1 mb-4px mb-md-8px">{{item.txt}}</u-txt-default>
                                    <u-tit-default>{{item.title}}</u-tit-default>   
                                </div>    
                                <txt-dot v-for="(detail, index) in item.info" :key="index" :class="index !== 0 ? 'mt-4px mt-md-8px' : ''">{{detail}}</txt-dot>                         
                            </v-col>
                        </v-row>
                    </dialog-primary>
                </v-col>
            </v-row>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import TxtDot from "@/components/publish/styles/typography/txt-dot.vue";
import DialogPrimary from "@/components/publish/parents/dialogs/dialog-primary.vue";
import BtnTextPrimary2 from "@/components/publish/parents/buttons/btn-text-primary2.vue";
import ImageRow from "@/sets/styles/image-row.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        TxtDot,
        DialogPrimary,
        BtnTextPrimary2,
        ImageRow,
    },
    data() {
        return {
            team: [
                {
                    title: "Daniel Choi",
                    txt: "President/CEO",
                    image: "/images/sub/intro/team/team-img2.jpg",
                    info: [
                        "Over 18 years of experience in the renewable energy sector at Samsung C&T",
                        "Developed more than 12GW of renewable energy projects in the US and Canada and monetized 3GW after successful achievement of NTP or COD",
                        "Initiated and managed the tripartite agreement between ON provincial government and the Korean consortium",
                        "Led all the procedure of land acquisition, interconnection studies, environmental permit & approvals, financing, construction, and sales of projects",
                        "Led and operated the development entity and its wind assets in Ontario, Canada",
                        "Brought top-tier makers to establish and operate the domestic manufacturing facilities",
                        "Led strategic partnership with several local developers to co-develop solar projects",
                        "Expert on financing, EPC contract, major equipment such as module, inverter, tracker, etc.",
                        "Expert on generation interconnection processes and tariffs of PJM, MISO, ERCOT, CAISO, SOCO, etc.",
                    ],
                },
                {
                    title: "Tae Kwak",
                    txt: "Vice President",
                    image: "/images/sub/intro/team/team-img3.jpg",
                    info: [
                        "17 years of experience in the project development, 11 years out of which in the energy and renewable sector at Samsung C&T",
                        "Developed more than 6GW of renewable energy projects in the US",
                        "Led proposal/marketing activities for the utility and corporate offtakers in CA",
                        "Supervised overall development/marketing activities of 3.5GW solar and energy storage development pipeline in the Western US",
                        "Reviewed and analyzed the mid- and late-stage solar projects for acquisition with the engineering team’s support",
                        "Supervising daily development activities of Standalone BESS projects in ERCOT/CAISO",
                        "Developed siting criteria of Standalone BESS projects in consideration of revenue stream available in each market",
                        "Expert on the project organizing and management with various stakeholders",
                        "Worked as Financial Analyst with Project Finance and Guarantees Unit, the World Bank (2012~2014)",
                        "CFA Charterholder (2008)",
                    ],
                },
                {
                    title: "Jihun Hwang",
                    txt: "Director",
                    image: "/images/sub/intro/team/team-img4.jpg",
                    info: [
                        "14 years of in the project development, 14 years out of which in the energy experiencend renewable sector at Samsung C&T, Hanhwa Energy and LS Electric.",
                        "Developed more than 5GW of renewable energy projects in the US, U.K., Europe, Australia and South Korea.",
                        "Led interconnection/proposal/marketing activities for the utility and corporate offtakers in TX",
                        "Supervised overall development activities of a 2.2GW solar and energy storage development pipeline in Texas",
                        "Supervising development activities of Standalone BESS projects in ERCOT/NYISO/SPP",
                        "Overall reviewed and analyzed the mid- and late-stage Solar+BESS for acquisition/construction with the engineering team’s support.",
                        "Supervised the early-, mid-, and late-stage Standalone BESS for acquisition/construction/operation overall with the EPC team’s support. ",
                        "Developed siting criteria of standalone BESS projects in consideration of revenue stream available in each market",
                        "Expert on the project organizing and management with various stakeholders",
                    ],
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.team-item{
    position: relative;
    overflow: hidden;
    &__image{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: scale(1);
        filter: blur(0);
        transition: .25s ease-out;
    }
    &__inner{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
        opacity: 1;
        visibility: visible;
        transition: .25s ease-out;
    }
    &__active{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .8);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: .25s ease-out;
        >*{
            transform: scale(1.4);
            opacity: 0;
            visibility: hidden;
            transition: .25s ease-out;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .team-item{
        &:hover{
            .team-item__image{
                transform: scale(1.1);
                filter: blur(10px);
            }
            .team-item__inner{
                opacity: 0;
                visibility: hidden;
            }
            .team-item__active{
                opacity: 1;
                visibility: visible;
                &>*{
                    transform: scale(1);
                    opacity: 1;
                    visibility: visible;
                    transition: .35s ease-out;
                    &:nth-child(1){
                        transition-delay: .1s;
                    }
                    &:nth-child(2){
                        transition-delay: .2s;
                    }
                    &:nth-child(3){
                        transition-delay: .3s;
                    }
                }
            }
        }
    }
}
@media (min-width:1200px){
}

</style>
