var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('board-filter', {
    on: {
      "searchKey": function (key) {
        _vm.filter.searchKey = key;
      },
      "searchValue": function (value) {
        _vm.filter.searchValue = value;
      },
      "search": function ($event) {
        return _vm.search();
      }
    }
  }), _c('div', {
    staticClass: "border-t border-2 border-dark",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('v-row', {
    staticClass: "mx-n10px mx-md-0",
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.boards, function (news, index) {
    return _c('v-col', {
      key: index,
      staticClass: "px-10px px-md-0",
      attrs: {
        "cols": "6",
        "md": "12"
      }
    }, [_c('router-link', {
      staticClass: "news-item d-block py-16px py-md-24px border-b border-light",
      attrs: {
        "to": `/news/${news._id}`
      }
    }, [_c('v-row', {
      staticClass: "row--xl"
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('div', {
      staticClass: "rounded-lg overflow-hidden"
    }, [_c('v-img', {
      staticClass: "news-item__image w-100 min-w-md-300px",
      attrs: {
        "src": news.thumb ? news.thumb : '/images/common/noimg.svg',
        "aspect-ratio": 300 / 200
      }
    })], 1)]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": ""
      }
    }, [_c('v-row', {
      staticClass: "h-100 pt-16px pb-md-16px flex-column",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', [_c('u-txt-default', {
      staticClass: "txt--sm line-height-1 mb-6px mb-md-12px"
    }, [_vm._v(_vm._s(_vm.$dayjs(news.createdAt).format("YYYY-MM-DD")))]), _c('u-tit-default', {
      staticClass: "tit--sm font-weight-semibold text-truncate-2"
    }, [_vm._v(" " + _vm._s(news.subject) + " ")])], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "pt-8px pt-md-20px"
    }, [_c('btn-text-primary', [_vm._v("Learn More")])], 1)])], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('pagination-primary', {
    attrs: {
      "data-aos": "fade-up",
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }