import { render, staticRenderFns } from "./u-btn-icon-outlined-circle.vue?vue&type=template&id=90360134&scoped=true&"
import script from "./u-btn-icon-outlined-circle.vue?vue&type=script&lang=js&"
export * from "./u-btn-icon-outlined-circle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90360134",
  null
  
)

export default component.exports