<template>
    <u-pagination-elevated-rounded v-bind="$attrs"></u-pagination-elevated-rounded>
</template>

<script>
import UPaginationElevatedRounded from "@/components/publish/styles/paginations/u-pagination-elevated-rounded.vue";

export default {
    props: {},
    components: {
        UPaginationElevatedRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>

