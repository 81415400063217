<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>sub-head-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <sub-head-primary itemTitle="가이드" tabActive="예시페이지" bg="/images/test.jpg">
                    <template #tit>가이드</template>
                </sub-head-primary>
            </v-col>
            <v-col cols="12">
                <sub-head-secondary itemTitle="가이드" tabActive="예시페이지" bg="/images/test.jpg">
                    <template #tit>가이드</template>
                </sub-head-secondary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- With -->
        <h1>With</h1>
        <p><u>sub-head--with-header</u></p>
        <p><u>sub-head--with-tabs</u></p>
        <p>위 클래스 추가 시 header 또는 tab이 이미지영역 위로 올라옵니다.</p>
        <p><u>sub-head--none-tabs</u></p>
        <p>
            sub-head--none-tabs추가 시 탭영역만큼 높이가 커집니다.<br />
            sub-head--with-tabs을 기본으로 사용하지만 하위 메뉴가 없는 페이지, tab이 없는 페이지에 사용합니다.<br />
            ex) &lt;sub-head-primary class="sub-head--none-tabs"&gt;
        </p>
        <!-- // Default -->
    </div>
</template>

<script>
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import SubHeadSecondary from "@/sets/parents/pages/sub-head-secondary.vue";

export default {
    props: {},
    components: {
        SubHeadPrimary,
        SubHeadSecondary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// 가이드 작업을 위해 넣어 놓은 스타일입니다.
.sub-head--with-header {
    margin-top: 0;
}
</style>
