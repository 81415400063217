var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "CONTACT",
            "bg": "/images/sub/sv-contact.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("CONTACT")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("CONTACT")];
      },
      proxy: true
    }])
  }, [_c('iframe', {
    staticClass: "rounded-lg d-block mb-40px mb-md-80px",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "data-aos": "fade-up",
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3017.746441588164!2d-73.96724612355571!3d40.855490428818264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f6c15de48fd5%3A0x3a5dc00555acc457!2s1%20Brg%20Plz%20N%20%23665%2C%20Fort%20Lee%2C%20NJ%2007024%2C%20USA!5e0!3m2!1sen!2skr!4v1705553998045!5m2!1sen!2skr",
      "width": "100%",
      "height": "500",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  }), _c('v-row', {
    attrs: {
      "justify": "center",
      "no-gutters": ""
    }
  }, _vm._l(_vm.contact, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "border-light",
      class: {
        'border-l': index !== 0 && _vm.$vuetify.breakpoint.mdAndUp,
        'border-t': index !== 0 && !_vm.$vuetify.breakpoint.mdAndUp
      },
      attrs: {
        "cols": "12",
        "md": "",
        "data-aos": "fade-up",
        "data-aos-delay": _vm.$vuetify.breakpoint.mdAndUp ? index * 100 : 0
      }
    }, [_c('card-secondary', {
      attrs: {
        "innerClass": "pa-16px px-md-24px py-md-0 text-center"
      }
    }, [_c('v-img', {
      staticClass: "mx-auto mb-12px mb-md-24px",
      attrs: {
        "src": item.icon,
        "max-width": "48",
        "aspect-ratio": 1 / 1
      }
    }), _c('u-tit-default', {
      staticClass: "tit--xs line-height-1"
    }, [_vm._v(_vm._s(item.title))]), _c('u-txt-default', [_vm._v(_vm._s(item.txt))])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }