var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({
    staticClass: "tit-wrap--sm"
  }, 'u-tit-wrap-default', _vm.$attrs, false), [_c('u-tit-default', {
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._t("default")], 2), this.$slots['txt'] ? _c('u-txt-default', {
    staticClass: "txt--lg line-height-15",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._t("txt")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }