var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "WHO WE ARE",
            "tabActive": "OUR COMPANY",
            "bg": "/images/sub/sv-intro.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("WHO WE ARE")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("OUR COMPANY")];
      },
      proxy: true
    }])
  }, _vm._l(_vm.company, function (item, index) {
    return _c('image-row', {
      key: index,
      class: index !== 0 ? 'pt-20px pt-md-0' : '',
      attrs: {
        "image": item.image,
        "reverse": index % 2 !== 0
      }
    }, _vm._l(item.txt, function (detail) {
      return _c('p', {
        key: detail
      }, [_vm._v(_vm._s(detail))]);
    }), 0);
  }), 1), _c('page-section-primary', {
    staticClass: "page-section--last pt-0"
  }, [_c('tit-wrap-secondary', {
    scopedSlots: _vm._u([{
      key: "txt",
      fn: function () {
        return [_vm._v("VISIT THE WEBSITE BELOW")];
      },
      proxy: true
    }])
  }, [_vm._v(" FOR MORE INFORMATION ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": 100 * (0 + 1),
      "cols": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "site-item v-card--none-active-bg",
    attrs: {
      "href": _vm.site1.link,
      "target": "_blank"
    }
  }, [_c('v-img', {
    staticClass: "site-item__logo",
    attrs: {
      "src": _vm.site1.logo,
      "aspect-ratio": 280 / 64
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": 100 * (1 + 1),
      "cols": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "site-item v-card--none-active-bg",
    attrs: {
      "target": "_blank"
    }
  }, [_c('v-img', {
    staticClass: "site-item__logo",
    attrs: {
      "src": _vm.site2.logo,
      "aspect-ratio": 280 / 64
    },
    on: {
      "click": _vm.warning
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }