<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="WHAT WE DO" tabActive="OUR WAY" bg="/images/sub/sv-about.jpg">
                <template #title>WHAT WE DO</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <title-image-row image="/images/sub/about/way/way-img.jpg">
                <template #title>OUR WAY</template>
                <p>CS Renewables understands the communities' needs and concerns. From land acquisition to operation, we create jobs, we build and maintain good, long term relationships with landowners and residents. Our focus is to construct renewable facilities via eco-friendly methods and techniques. Our projects will contribute to local communities every step of the way.</p>
            </title-image-row>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import TitleImageRow from "@/sets/styles/title-image-row.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        TitleImageRow,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
