<template>
    <u-card-outlined-rounded v-bind="$attrs">
        <template v-if="innerClass || cardInnerSize">
            <div class="card-inner" :class="[innerClass, cardInnerSize]">
                <slot />
            </div>
        </template>
        <template v-else>
            <slot />
        </template>
    </u-card-outlined-rounded>
</template>

<script>
import UCardOutlinedRounded from "@/components/publish/styles/cards/u-card-outlined-rounded.vue";

export default {
    props: {
        innerClass: { type: String, default: "" },
        cardInnerSize: { type: String, default: "" },
    },
    components: {
        UCardOutlinedRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
