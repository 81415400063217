<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="CAREERS" bg="/images/sub/sv-careers.jpg">
                <template #title>CAREERS</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first">
            <title-image-row image="/images/sub/careers/careers-img.jpg">
                <template #title>CAREERS</template>
                <p>CS Renewables is looking for purpose-driven people, passionate about renewable energy, ready to work hard with a team player mentality, dedication and honesty.</p>
            </title-image-row>
        </page-section-primary>

        <page-section-primary style="background-image: url(/images/sub/careers/careers-bg.jpg)">
            <tit-wrap-primary titClass="mx-auto white--text">
                JOIN OUR TEAM
            </tit-wrap-primary>
            <v-row>
                <v-col v-for="item in team" :key="item.title" cols="12" sm="6" md="4">
                    <card-secondary color="rgba(0, 0, 0, .2)" rounded="lg" innerClass="pa-20px pa-md-30px pa-lg-40px text-center" class="h-100">
                        <v-img data-aos="zoom-out" data-aos-delay="200" :src="item.icon" :aspect-ratio="1 / 1" class="w-60px w-md-80px mb-16px mb-md-32px mx-auto" />
                        <u-tit-default data-aos="fade-up" class="text-uppercase white--text line-height-1 font-weight-semibold">
                            {{item.title}}
                        </u-tit-default>
                        <u-txt-default data-aos="fade-up" data-aos-delay="100" class="txt--lg white--text line-height-15 mt-8px mt-md-16px">
                            <p v-html="item.txt"></p>
                        </u-txt-default>
                    </card-secondary>
                </v-col>
            </v-row>
        </page-section-primary>

        <page-section-primary class="page-section--last" style="background-image: url(/images/sub/careers/careers-bg2.jpg)">
            <v-row align="center" class="row--xl">
                <v-col cols="12" md="">
                    <tit-wrap-secondary class="pb-0 white--text">
                        PLEASE SEND YOUR RESUME TO
                        <template #txt>info@csrenewables.us</template>
                    </tit-wrap-secondary>
                </v-col>
                <v-col cols="12" md="auto" class="mt-10px mt-md-0">
                    <btn-primary2 data-aos="zoom-out" data-aos-delay="200" href="mailto: info@csrenewables.us" target="_blank" x-large class="v-btn--width-fixed">Apply</btn-primary2>
                </v-col>
            </v-row>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardSecondary from "@/components/publish/parents/cards/card-secondary.vue";
import BtnPrimary2 from "@/components/publish/parents/buttons/btn-primary2.vue";
import TitleImageRow from "@/sets/styles/title-image-row.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TitWrapPrimary,
        TitWrapSecondary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardSecondary,
        BtnPrimary2,
        TitleImageRow,
    },
    data() {
        return {
            team: [
                {
                    title: "future",
                    txt: "Our mission is to deliver <br class='d-none d-xl-block' />the best future for everyone <br class='d-none d-xl-block' />through clean energy",
                    icon: "/images/sub/careers/careers-icon.svg",
                },
                {
                    title: "team",
                    txt: "We think about the collective <br class='d-none d-xl-block' />good and work together <br class='d-none d-xl-block' />with team spirit",
                    icon: "/images/sub/careers/careers-icon2.svg",
                },
                {
                    title: "opportunities",
                    txt: "We invest in opportunities <br class='d-none d-xl-block' />for professional development <br class='d-none d-xl-block' />and growth",
                    icon: "/images/sub/careers/careers-icon3.svg",
                },
                {
                    title: "honesty",
                    txt: "We interact with each <br class='d-none d-xl-block' />other with honesty <br class='d-none d-xl-block' />and integrity ",
                    icon: "/images/sub/careers/careers-icon4.svg",
                },
                {
                    title: "success",
                    txt: "We work hard to meet <br class='d-none d-xl-block' />deadlines and <br class='d-none d-xl-block' />achieve success",
                    icon: "/images/sub/careers/careers-icon5.svg",
                },
                {
                    title: "welfare",
                    txt: "Each and every employee’s <br class='d-none d-xl-block' />well-being is of <br class='d-none d-xl-block' />crucial importance",
                    icon: "/images/sub/careers/careers-icon6.svg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
