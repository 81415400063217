var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxl",
    class: {
      'flex-md-row-reverse': _vm.reverse
    },
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded-lg",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": _vm.image,
      "aspect-ratio": 560 / 400,
      "max-width": "560"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-4px mt-md-0",
    attrs: {
      "data-aos": "fade-up",
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-txt-default', {
    staticClass: "txt--lg line-height-15"
  }, [_vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }