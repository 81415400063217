var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "CAREERS",
            "bg": "/images/sub/sv-careers.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("CAREERS")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first"
  }, [_c('title-image-row', {
    attrs: {
      "image": "/images/sub/careers/careers-img.jpg"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("CAREERS")];
      },
      proxy: true
    }])
  }, [_c('p', [_vm._v("CS Renewables is looking for purpose-driven people, passionate about renewable energy, ready to work hard with a team player mentality, dedication and honesty.")])])], 1), _c('page-section-primary', {
    staticStyle: {
      "background-image": "url(/images/sub/careers/careers-bg.jpg)"
    }
  }, [_c('tit-wrap-primary', {
    attrs: {
      "titClass": "mx-auto white--text"
    }
  }, [_vm._v(" JOIN OUR TEAM ")]), _c('v-row', _vm._l(_vm.team, function (item) {
    return _c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4"
      }
    }, [_c('card-secondary', {
      staticClass: "h-100",
      attrs: {
        "color": "rgba(0, 0, 0, .2)",
        "rounded": "lg",
        "innerClass": "pa-20px pa-md-30px pa-lg-40px text-center"
      }
    }, [_c('v-img', {
      staticClass: "w-60px w-md-80px mb-16px mb-md-32px mx-auto",
      attrs: {
        "data-aos": "zoom-out",
        "data-aos-delay": "200",
        "src": item.icon,
        "aspect-ratio": 1 / 1
      }
    }), _c('u-tit-default', {
      staticClass: "text-uppercase white--text line-height-1 font-weight-semibold",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('u-txt-default', {
      staticClass: "txt--lg white--text line-height-15 mt-8px mt-md-16px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "100"
      }
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.txt)
      }
    })])], 1)], 1);
  }), 1)], 1), _c('page-section-primary', {
    staticClass: "page-section--last",
    staticStyle: {
      "background-image": "url(/images/sub/careers/careers-bg2.jpg)"
    }
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('tit-wrap-secondary', {
    staticClass: "pb-0 white--text",
    scopedSlots: _vm._u([{
      key: "txt",
      fn: function () {
        return [_vm._v("info@csrenewables.us")];
      },
      proxy: true
    }])
  }, [_vm._v(" PLEASE SEND YOUR RESUME TO ")])], 1), _c('v-col', {
    staticClass: "mt-10px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('btn-primary2', {
    staticClass: "v-btn--width-fixed",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "href": "mailto: info@csrenewables.us",
      "target": "_blank",
      "x-large": ""
    }
  }, [_vm._v("Apply")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }