var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-txt-default', {
    staticClass: "line-height-15"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "pr-4px pr-md-8px"
  }, [_vm._v("·")])]), _c('v-col', [_c('p', [_vm._t("default")], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }