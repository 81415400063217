var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "WHAT WE DO",
            "tabActive": "OUR WAY",
            "bg": "/images/sub/sv-about.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("WHAT WE DO")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('title-image-row', {
    attrs: {
      "image": "/images/sub/about/way/way-img.jpg"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("OUR WAY")];
      },
      proxy: true
    }])
  }, [_c('p', [_vm._v("CS Renewables understands the communities' needs and concerns. From land acquisition to operation, we create jobs, we build and maintain good, long term relationships with landowners and residents. Our focus is to construct renewable facilities via eco-friendly methods and techniques. Our projects will contribute to local communities every step of the way.")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }