var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "WHO WE ARE",
            "tabActive": "OUR TEAM",
            "bg": "/images/sub/sv-intro.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("WHO WE ARE")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("OUR TEAM")];
      },
      proxy: true
    }])
  }, [_c('image-row', {
    attrs: {
      "image": "/images/sub/intro/team/team-img.jpg"
    }
  }, [_c('p', [_vm._v("We are proud to introduce the best team with 70+ years of combined experience. The CSR team expertly manages even the most complicated projects from concept to completion, and helps utilities, municipalities, cooperatives, and corporate customers deliver dependable, customized energy solutions.")])])], 1), _c('page-section-primary', {
    staticClass: "page-section--last pt-0"
  }, [_c('v-row', _vm._l(_vm.team, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": 100 * (index + 1),
        "cols": "6",
        "sm": "4"
      }
    }, [_c('dialog-primary', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('v-card', _vm._g(_vm._b({
            staticClass: "team-item",
            attrs: {
              "flat": "",
              "rounded": "lg"
            }
          }, 'v-card', attrs, false), on), [_c('v-responsive', {
            attrs: {
              "aspect-ratio": 384 / 500,
              "max-width": "384"
            }
          }, [_c('v-img', {
            staticClass: "team-item__image",
            attrs: {
              "src": item.image,
              "aspect-ratio": 384 / 500
            }
          }), _c('div', {
            staticClass: "team-item__inner pa-16px pa-md-24px"
          }, [_c('u-txt-default', {
            staticClass: "white--text mb-4px mb-md-8px"
          }, [_vm._v(_vm._s(item.txt))]), _c('u-tit-default', {
            staticClass: "white--text tit--sm"
          }, [_vm._v(_vm._s(item.title))])], 1), _c('div', {
            staticClass: "team-item__active pa-16px"
          }, [_c('u-txt-default', {
            staticClass: "white--text line-height-1 mb-4px mb-md-8px"
          }, [_vm._v(_vm._s(item.txt))]), _c('u-tit-default', {
            staticClass: "white--text"
          }, [_vm._v(_vm._s(item.title))]), _c('div', {
            staticClass: "mt-8px mt-md-20px"
          }, [_c('btn-text-primary2', {
            attrs: {
              "size": "small"
            }
          }, [_vm._v("Learn More")])], 1)], 1)], 1)], 1)];
        }
      }], null, true)
    }, [_c('v-row', {
      staticClass: "row--xl",
      attrs: {
        "align-xl": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "4",
        "lg": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "src": item.image,
        "aspect-ratio": 384 / 500,
        "max-width": "384",
        "width": _vm.$vuetify.breakpoint.lgAndUp ? 384 : ''
      }
    })], 1), _c('v-col', {
      staticClass: "mt-10px mt-md-0",
      attrs: {
        "cols": "12",
        "md": "8",
        "lg": ""
      }
    }, [_c('div', {
      staticClass: "pb-12px pb-md-24px"
    }, [_c('u-txt-default', {
      staticClass: "primary--text line-height-1 mb-4px mb-md-8px"
    }, [_vm._v(_vm._s(item.txt))]), _c('u-tit-default', [_vm._v(_vm._s(item.title))])], 1), _vm._l(item.info, function (detail, index) {
      return _c('txt-dot', {
        key: index,
        class: index !== 0 ? 'mt-4px mt-md-8px' : ''
      }, [_vm._v(_vm._s(detail))]);
    })], 2)], 1)], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }