<template>
    <div>
        <board-filter
        @searchKey="(key)=>{filter.searchKey= key}"
        @searchValue="(value)=>{filter.searchValue= value}"
        @search="search()"
        />

        <div data-aos="fade-up" class="border-t border-2 border-dark">
            <v-row no-gutters class="mx-n10px mx-md-0">

                <!-- 6개씩 페이징 -->
                <!-- S: news -->
                <v-col v-for="(news, index) in boards" :key="index" cols="6" md="12" class="px-10px px-md-0">
                    <router-link :to="`/news/${news._id}`" class="news-item d-block py-16px py-md-24px border-b border-light">
                        <v-row class="row--xl">
                            <v-col cols="12" md="auto">
                                <div class="rounded-lg overflow-hidden">
                                    <v-img :src="news.thumb ? news.thumb : '/images/common/noimg.svg'" :aspect-ratio="300 / 200" class="news-item__image w-100 min-w-md-300px" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="">
                                <v-row no-gutters class="h-100 pt-16px pb-md-16px flex-column">
                                    <v-col>
                                        <u-txt-default class="txt--sm line-height-1 mb-6px mb-md-12px">{{ $dayjs(news.createdAt).format("YYYY-MM-DD") }}</u-txt-default>
                                        <u-tit-default class="tit--sm font-weight-semibold text-truncate-2">
                                           {{ news.subject }}
                                        </u-tit-default>
                                    </v-col>
                                    <v-col cols="auto">
                                        <div class="pt-8px pt-md-20px">
                                            <btn-text-primary>Learn More</btn-text-primary>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </router-link>
                </v-col>
                <!-- E: news -->

            </v-row>
        </div>

        <div class="pagination-wrap">
            <pagination-primary  data-aos="fade-up" v-model="page" :length="pageCount" :total-visible="11" @input="search()"></pagination-primary>
        </div>

    </div>
</template>

<script>
import api from "@/api";
import BoardFilter from "@/components/client/board/board-filter.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import BtnTextPrimary from "@/components/publish/parents/buttons/btn-text-primary.vue";
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";

export default {
    props: {
    },
    components: {
        BoardFilter,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        BtnTextPrimary,
        PaginationPrimary,
    },
    data() {
        return {
            boards: [],

            page: 1,
            limit: 6,
            pageCount: 0,
            filter:{
                searchKey: null,
                searchValue: null
            }
        };
    },
    mounted(){
        this.init();
    },
    methods:{
        async init(){
            await this.search();
        },
        async search(routable = true) {
            try{
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                })};
                let { summary, boards } = await api.v1.boards.gets({
                    headers: { skip: (this.page - 1) * this.limit, limit: this.limit },
                    params: this.filter
                });
                this.boards = boards;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .news-item{
        &__image{
            transform: scale(1);
            transition: .2s ease-out;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .4);
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                transition: .2s ease-out;
            }
        }
        &:hover{
            .news-item__image{
                transform: scale(1.2);
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

</style>