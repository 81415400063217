<template>
    <div v-if="board">
        <div data-aos="fade-up" class="board-view">
            <div class="board-view__row board-view__row--title">
                {{board.subject}}
            </div>
            <div class="board-view__row board-view__row--info">
                <v-row>
                    <v-col cols="auto">
                        name : admin
                        <!-- {{ board.writer.name }} -->
                    </v-col>
                    <v-col cols="auto">
                        date : {{ $dayjs(board.createdAt).format("YYYY-MM-DD HH:mm:ss")}}
                    </v-col>
                    <v-col cols="auto">
                        view : {{ board.viewCount }}
                    </v-col>
                </v-row>
            </div>
            <div class="board-view__row board-view__row">
                <div v-html="board.content"></div>
            </div>
        </div>
        <div class="mt-24px">
            <v-row justify="end">
                <v-col cols="auto">
                    <btn-secondary2 @click="$router.go(-1)">List</btn-secondary2>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import BtnSecondary2 from "@/components/publish/parents/buttons/btn-secondary2.vue";

export default {
    props: {
    },
    components: {
        BtnSecondary2,
    },
    data(){
        return {
            board: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(){
            try{
                let { board } = await api.v1.boards.get({_id:this.$route.params._news});
                
                this.board = board;
            } catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
}
</script>

<style lang="scss" scoped></style>