var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxl flex-md-row-reverse"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "rounded-lg",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "200",
      "src": _vm.image,
      "aspect-ratio": 588 / 500,
      "max-width": "588"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "pr-md-56px pt-md-48px"
  }, [_c('tit-wrap-primary', {
    staticClass: "tit-wrap--sm"
  }, [_vm._t("title")], 2), _c('u-txt-default', {
    staticClass: "txt--lg line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._t("default")], 2)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }