var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('sub-head-primary', {
    attrs: {
      "itemTitle": "가이드",
      "tabActive": "예시페이지",
      "bg": "/images/test.jpg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("가이드")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('sub-head-secondary', {
    attrs: {
      "itemTitle": "가이드",
      "tabActive": "예시페이지",
      "bg": "/images/test.jpg"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("가이드")];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("With")]), _vm._m(1), _vm._m(2), _c('p', [_vm._v("위 클래스 추가 시 header 또는 tab이 이미지영역 위로 올라옵니다.")]), _vm._m(3), _vm._m(4)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("sub-head-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("sub-head--with-header")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("sub-head--with-tabs")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("sub-head--none-tabs")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" sub-head--none-tabs추가 시 탭영역만큼 높이가 커집니다."), _c('br'), _vm._v(" sub-head--with-tabs을 기본으로 사용하지만 하위 메뉴가 없는 페이지, tab이 없는 페이지에 사용합니다."), _c('br'), _vm._v(" ex) <sub-head-primary class=\"sub-head--none-tabs\"> ")]);

}]

export { render, staticRenderFns }