<template>
    <u-tit-wrap-default v-bind="$attrs" class="tit-wrap--sm">
        <u-tit-default data-aos="fade-up"><slot /></u-tit-default>
        <u-txt-default v-if="this.$slots['txt']" data-aos="fade-up" data-aos-delay="100" class="txt--lg line-height-15"><slot name="txt" /></u-txt-default>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.white--text{
    .txt{
        color: #fff;
    }
}
</style>
