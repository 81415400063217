<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="WHO WE ARE" tabActive="OUR COMPANY" bg="/images/sub/sv-intro.jpg">
                <template #title>WHO WE ARE</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first">
            <template #tit>OUR COMPANY</template>
            <image-row v-for="(item, index) in company" :key="index" :class="index !== 0 ? 'pt-20px pt-md-0' : ''"
            :image="item.image" :reverse="index % 2 !== 0">
                <p v-for="detail in item.txt" :key="detail">{{detail}}</p>
            </image-row>
        </page-section-primary>

        <page-section-primary class="page-section--last pt-0">
            <tit-wrap-secondary>
                FOR MORE INFORMATION
                <template #txt>VISIT THE WEBSITE BELOW</template>
            </tit-wrap-secondary>
            <v-row>
                <!-- 0214 두번째 링크 클릭 시 alert 창 -->
                <!-- <v-col v-for="(item, index) in site" :key="index" data-aos="fade-up" :data-aos-delay="100*(index+1)" cols="6" md="3">
                    <card-primary :href="item.link" target="_blank" class="site-item v-card--none-active-bg" >
                        <v-img :src="item.logo" :aspect-ratio="280 / 64" class="site-item__logo" />
                    </card-primary>
                </v-col> -->
                <v-col data-aos="fade-up" :data-aos-delay="100*(0+1)" cols="6" md="3">
                    <card-primary :href="site1.link" target="_blank" class="site-item v-card--none-active-bg" >
                        <v-img :src="site1.logo" :aspect-ratio="280 / 64" class="site-item__logo" />
                    </card-primary>
                </v-col>
                <v-col data-aos="fade-up" :data-aos-delay="100*(1+1)" cols="6" md="3">
                    <card-primary target="_blank" class="site-item v-card--none-active-bg" >
                        <v-img :src="site2.logo" :aspect-ratio="280 / 64" class="site-item__logo" @click="warning" />
                    </card-primary>
                </v-col>
            </v-row>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import ImageRow from "@/sets/styles/image-row.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TitWrapSecondary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        CardPrimary,
        ImageRow,
    },
    data() {
        return {
            company: [
                {
                    image: "/images/sub/intro/company/company-img.jpg",
                    txt: [
                        "CS Renewables, Inc. is a joint venture between CS Wind America and CS Energy Corporation. Both shareholders are subsidiaries entirely owned by CS Wind Corporation.",
                    ]
                },
                {
                    image: "/images/sub/intro/company/company-img2.jpg",
                    txt: [
                        "CS Wind is the Global No.1 Wind Tower manufacturer and owns the largest wind tower factory in the world. ",
                        "It is expanding its business further to the renewable energy development in the US since acquiring the wind tower factory from Vestas in 2021. ",
                    ]
                },
                {
                    image: "/images/sub/intro/company/company-img3.jpg",
                    txt: [
                        "Its commitment to green energy, its renowned brand and strong financial capacity have led to the successful launching of CS Renewables. ",
                    ]
                },
            ],
            // site: [
            //     {
            //         logo: "/images/sub/intro/company/site-logo.svg",
            //         link: "https://cswind.com/en/",
            //     },
            //     {
            //         logo: "/images/sub/intro/company/site-logo2.svg",
            //         link: "https://csenergy.co.kr/en/",
            //     },
            // ],
            site1: {
                logo: "/images/sub/intro/company/site-logo.svg",
                link: "https://cswind.com/en/",
            },
            site2: {
                logo: "/images/sub/intro/company/site-logo2.svg",
                link: "https://csenergy.co.kr/en/",
            },
        };
    },
    mounted() {},
    methods: {
        warning(){
            alert("Rebuilding CS Energy Website");
        }
    },
};
</script>

<style lang="scss" scoped>
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .site-item{
        overflow: hidden;
        &__logo{
            transform: scale(1);
            transition: .15s ease-out;
        }
        &:hover{
            .site-item__logo{
                transform: scale(1.12);
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}
@media (min-width:1200px){
}
</style>
