<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="WHO WE ARE" tabActive="OUR VALUES" bg="/images/sub/sv-intro.jpg">
                <template #title>WHO WE ARE</template>
            </sub-head-primary>
        </template>

        <page-section-primary class="page-section--first page-section--last">
            <template #pageSectionHead>
                <v-img data-aos="fade-up" src="/images/sub/intro/values/values-img.jpg" max-width="1920" :aspect-ratio="1920 / 400" class="mx-auto mb-40px mb-md-80px" />
            </template>
            <v-row no-gutters class="mx-md-n40px">
                <v-col cols="12" md="6" class="px-md-40px" >
                    <tit-wrap-primary class="tit-wrap--sm">
                        OUR VALUES
                    </tit-wrap-primary>
                </v-col>
                <v-col cols="12" md="6" class="px-md-40px" >
                    <u-txt-default class="txt--lg line-height-15">
                        <p data-aos="fade-up">
                            CS Renewables(“CSR”) is a utility-scale renewable energy and storage developer focused on accelerating clean energy future in the US. 
                        </p>
                        <p data-aos="fade-up">
                            Due to the ongoing climate change, natural disasters happen more and more often and affect communities economically and socially. In addition to this issue and the increasing demand in electricity, clean renewable energy gains prominence. 
                        </p>
                        <p data-aos="fade-up">
                            This is why we are dedicated to the renewable energy industry. We are leading the way to influence people, businesses and institutions throughout the US, with the commitment to work for the future, for the people, for the world.
                        </p>
                    </u-txt-default>
                </v-col>
            </v-row>
        </page-section-primary>

    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TitWrapPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
