var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', [_c('div', {
    staticClass: "board-view",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_c('div', {
    staticClass: "board-view__row board-view__row--title"
  }, [_vm._v(" " + _vm._s(_vm.board.subject) + " ")]), _c('div', {
    staticClass: "board-view__row board-view__row--info"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" name : admin ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" date : " + _vm._s(_vm.$dayjs(_vm.board.createdAt).format("YYYY-MM-DD HH:mm:ss")) + " ")]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" view : " + _vm._s(_vm.board.viewCount) + " ")])], 1)], 1), _c('div', {
    staticClass: "board-view__row board-view__row"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])]), _c('div', {
    staticClass: "mt-24px"
  }, [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-secondary2', {
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("List")])], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }