var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "WHAT WE DO",
            "tabActive": "OUR MISSION",
            "bg": "/images/sub/sv-about.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("WHAT WE DO")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last"
  }, [_c('title-image-row', {
    attrs: {
      "image": "/images/sub/about/mission/mission-img.jpg"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("OUR MISSION")];
      },
      proxy: true
    }])
  }, [_c('p', [_vm._v("Our mission is to develop reliable and competitive renewable energy projects in a responsible way, which will create positive impacts both in communities and the climate change.")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }